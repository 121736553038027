import React from 'react'
const Image = ({featuredImage, title}) => {
    return <img
    className="testimonials-img"
    loading="lazy"
    src={featuredImage}
    alt={title}
  />
}

export default Image