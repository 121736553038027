import React from 'react'
import Button from './Testimonial/Button';
import Image from './Testimonial/Image';
import ItemText from './Testimonial/ItemText';
import Text from './Testimonial/Text';
import './Testimonials.scss'

class Testimonials extends React.Component {
    state = {
        title: "",
        designation: "",
        quote: "",
        featuredImage: "",
        total: 0,
        current:0,
        currentSlug: "",
        nextSlug:"",
        prevSlug: ""
    };

    changeTestimonial = (next) => {    
        const testimonialData = this.props.edges
        if(this.state.current < testimonialData.length-1){
          const displayingData = testimonialData[this.state.current+1]
          return this.setState((prevState) => ({
                title: displayingData.node.frontmatter.title,
                designation: displayingData.node.frontmatter.designation,
                quote: displayingData.node.frontmatter.quote,
                featuredImage: displayingData.node.frontmatter.featuredImage,
                currentSlug: displayingData.node.fields.slug,
                nextSlug: displayingData.next ? displayingData.next.fields.slug : "",
                prevSlug: displayingData.previous ? displayingData.previous.fields.slug : "",
                current: prevState.current + 1,
                total: testimonialData.length
            }))
        }else{
          const displayingData = testimonialData[0]
          return this.setState((prevState) => ({
                title: displayingData.node.frontmatter.title,
                designation: displayingData.node.frontmatter.designation,
                quote: displayingData.node.frontmatter.quote,
                featuredImage: displayingData.node.frontmatter.featuredImage,
                currentSlug: displayingData.node.fields.slug,
                nextSlug: displayingData.next ? displayingData.next.fields.slug : "",
                prevSlug: displayingData.previous ? displayingData.previous.fields.slug : "",
                current: 0,
                total: testimonialData.length
            }))  
        }
        // nextTestimonial.map(({node, next, previous}) => {
        // return this.setState((prevState) => ({
        //         title: node.frontmatter.title,
        //         designation: node.frontmatter.designation,
        //         description: node.frontmatter.description,
        //         featuredImage: node.frontmatter.featuredImage,
        //         currentSlug: node.fields.slug,
        //         nextSlug: next ? next.fields.slug : "",
        //         prevSlug: previous ? previous.fields.slug : "",
        //         current: status==="next" ? prevState.current + 1 : prevState.current - 1,
        //         total: testimonialData.length
        //     }))
        // })
    }

    componentDidMount = () => {
        const testimonialData = this.props.edges[0]
        this.setState({
            title: testimonialData.node.frontmatter.title,
            designation: testimonialData.node.frontmatter.designation,
            quote: testimonialData.node.frontmatter.quote,
            featuredImage: testimonialData.node.frontmatter.featuredImage,
            currentSlug: testimonialData.node.fields.slug,
            nextSlug: testimonialData.next ? testimonialData.next.fields.slug : "",
            prevSlug: testimonialData.previous ? testimonialData.previous.fields.slug : "",
            current: 0,
            total: this.props.edges.length
        })
    }

    render(){
        return (
          <div className="testimonials-page" id="common-pl">
            <div className="title-container">
              <p className="sub-title">What They</p>
              <h2>Say about us</h2>
            </div>
            <div className="testimonials-content">
              <div className="testimonials-img-container">
                <p className="vertical-text">
                  <ItemText
                    current={this.state.current + 1}
                    total={this.state.total}
                  />
                </p>
                <Image
                  featuredImage={this.state.featuredImage}
                  title={this.state.title}
                />
                <div className="testimonials-btn-container">
                  {/* <div className="next-prv-btn mb-2 mb-lg-0">{prevButton}</div> */}
              
                    <Button changeTestimonial={this.changeTestimonial} />
                </div>
              </div>
              <div className="testimonials-dec-container">
                <Text
                  description={this.state.quote}
                  title={this.state.title}
                  designation={this.state.designation}
                />
              </div>
            </div>
            <div className="background-text d-none d-lg-block">
              <img
                loading="lazy"
                src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614325549/TESTIMO_lxyw95.png"
                className="d-block w-100"
                alt="text"
              />
            </div>
          </div>
        )
    }
}

export default Testimonials