import React from 'react'
import Marked from 'react-markdown'
const Text = ({description, title, designation}) => {
    return (
        <div className="dec-card">
            <Marked parserOptions={{ commonmark: true}} source={description} />
            <div className="card-title">
                <p>{title}</p>
                <p>{designation}</p>
            </div>
        </div>
    )
}

export default Text